import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable/array/find';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import LoadingSpinner from '@Components/loadingSpinner';

import ChangeReport from '@Components/changereport';
import ComparisonTable from '@Components/comparisontable';
import TekstOgMedieSlider from '@Components/tekstogmedieslider/slider';
import TekstOgMedieSliderForside from '@Components/tekstogmedieslider/components/sliderforside';
import TilpasForsikringerFormular from '@Components/tilpasforsikringerformular/tilpasforsikringerformular';
import UdbetalingsForloeb from '@Components/PrognoseUdbetalingsForloeb/UdbetalingsForloeb';
import RecommendationList from '@Components/recommendation_list';
import { getQueryParam } from '@Utils/url';

export const WebRaadgivning = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "webraadgivning" */ '@Pages/WebRaadgivning'));

export const PensionsTjekFlow = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pensionstjek" */ '@Pages/PensionsTjekFlow'));

export const IddFlow = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "iddflow" */ '@Pages/iddFlow'));

export const Samlebank = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "samlebank" */ '@Pages/Samlebank'));

export const AabneSiderForside = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "aabne-sider-forside" */ '@Pages/aabneSider'));

export const MedlemsProfil = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "medlemsprofil" */ '@Pages/medlemsProfil'));

export const Survey = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "survey" */ '@Components/survey'));

export const ToKolonnerForskelligTekst = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/toKolonnerForskelligTekst'));
export const ToKolonnerMedBilleder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/toKolonnerMedBilleder'));
export const VideoQuoteBlockComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/videoQuoteBlock/videoQuoteBlock'));
export const VideoSkyfish = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/videoSkyfish'));
export const FarvetLinkOgIkonBokse = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/farvetLinkOgIkonBokse'));
export const Accordion = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/accordion'));
export const AccordionContainer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/accordion/components/accordions'));
export const AccordionTabList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/accordion/components/accordionTabList'));
export const Karrusel = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/karrusel'));
export const Faktaboks = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/faktaboks'));

export const SectionHeading = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/sectionHeading'));
export const ImageDeck = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/ImageDeck'));
export const Hotspots = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/hotspots'));
export const OptionButtons = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/optionButtons'));
export const Tiles = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/tiles'));
export const RelatedContentSlider = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/relatedContentSlider'));
export const TextDeck = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/textDeck'));
export const TextBlock = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/textBlock'));
export const Image = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/image'));
export const QuoteBlock = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/quoteBlock'));
export const EmployeeInfoBlock = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/employeeInfoBlock'));
export const LinkListe = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/linkListe'));
export const RelatedArticles = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "components" */ '@Components/relatedArticles'));

export const CprLogin = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "blocks" */ '@Components/cprLogin'));

export const SelvbetjeningBaseComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/SelvbetjeningBaseComponent'));
export const SelvbetjeningGageComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningGageComponent'));
export const SelvbetjeningGodkendComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningGodkendComponent'));
export const SelvbetjeningKvitteringComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningKvitteringComponent'));
export const SelvbetjeningStepComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningStepComponent'));
export const SelvbetjeningStepsComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningStepsComponent'));
export const SelvbetjeningTilpasForsikringComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningTilpasForsikringComponent'));
export const SelvbetjeningOmtegningFysComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningOmtegningFysComponent'));
export const SelvbetjeningKontoAendringComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningKontoAendringComponent'));
export const SelvbetjeningSkattekortComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "selvbetjening" */ '@Pages/selvbetjening/components/SelvbetjeningSkattekortComponent'));

export const MitidLoginComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "mitid" */ '@Components/mitidLogin'));
export const MitidSigningComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "mitid" */ '@Components/mitidSigningComponent'));
export const MitidSigningOffentligComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "mitid" */ '@Components/mitidSigningOffentligComponent'));
export const SsoPensionsinfo = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "mitid" */ '@Components/ssoPensionsinfo'));

export const MoedeBookingEnkeltBegivenhed = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "moede" */ '@Pages/MoedeBooking/components/moedebookingEnkeltBegivenhed'));
export const MoedeBookingOversigt = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "moede" */ '@Pages/MoedeBooking/moedebookingOversigt'));
export const BookWebraadgivning = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "moede" */ '@Pages/MoedeBooking/components/bookWebraadgivning'));
export const BookWebraadgivningMedPensionsinfo = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "moede" */ '@Pages/bookWebraadgivningMedPensionsinfo'));
export const HentPensionsinfoData = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "moede" */ '@Pages/MoedeBooking/components/hentPensionsinfoData'));

export const HelbredHovedSide = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "helbred" */ '@Components/helbredHovedSide'));
export const HelbredKontaktInfo = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "helbred" */ '@Components/HelbredKontaktInfo'));
export const HelbredTaleBobbel = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "helbred" */ '@Components/helbredTaleBobbel'));
export const HelbredUnderSide = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "helbred" */ '@Components/helbredUnderSide'));
export const Slider = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "helbred" */ '@Components/helbredsUnivers'));

export const KontaktOplysningerComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "kontakt" */ '@Pages/selvbetjening/components/SelvbetjeningKontaktOplysningerComponent/KontaktOplysningerComponent'));
export const KontaktOs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "kontakt" */ '@Pages/KontaktOs'));
export const KontaktOsApp = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "kontakt" */ '@Pages/KontaktOsApp'));
export const ListeAfSpoergsmaal = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "kontakt" */ '@Components/listeAfSpoergsmaal'));

export const ServicetjekNavigation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/Header'));
export const ServicetjekTilmelding = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/ServiceTjekTilmelding'));
export const ServicetjekBilledeOgTekstListe = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/TekstOgBilledeListe'));
export const ServicetjekLivshistorie = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/Livshistorie'));
export const ServicetjekHero = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/Hero'));
export const ServicetjekLivshaendelse = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/ServicetjekLivshaendelse/ServicetjekLivshaendelse'));
export const ServicetjekMaalrettetMeddelelse = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/MaalrettetMeddelelserSamlet'));
export const MaalrettetMeddelelseListe = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/MaalrettetMeddelelser'));
export const ServicetjekOnboarding = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Components/ServiceTjek/Onboarding'));
export const ServicetjekAarligt20 = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Pages/lhp/ServicetjekAarligt30'));
export const ServicetjekLoebende = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Pages/lhp/ServicetjekLoebende'));
export const ServicetjekResultat = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Pages/lhp/ServicetjekResultat'));
export const ServicetjekNy = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Pages/lhp/ServicetjekNy'));
export const ServicetjekSamtykke = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "servicetjek" */ '@Pages/lhp/ServicetjekSamtykke'));

export const DitPKAHero = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "ditpka" */ '@Pages/ditPKA/components/hero'));
export const DitPKAFilter = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "ditpka" */ '@Pages/ditPKA/components/filter'));
export const DitPKASharing = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "ditpka" */ '@Pages/ditPKA/components/sharing'));
export const DitPKAListe = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "ditpka" */ '@Pages/ditPKA/pages/articles'));

export const FooterContact = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "layout" */ '@Layout/footerContact'));
export const EmnePageHero = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "layout" */ '@Layout/emneSide/components/hero'));
export const CampainPageSectionHeading = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "layout" */ '@Layout/campainPage/components/sectionHeading'));
export const FrontPageHero = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "layout" */ '@Layout/frontpage/components/hero'));

export const TilmeldNyhedsbrevMedlemskommunikation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "ditpka" */ '@Pages/TilmeldNyhedsbrevMedlemskommunikation'));

export const PBUEkstra = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pbulandingpage" */ '@Pages/PBUCampaign/ekstra'));
export const PBUSaml = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pbulandingpage" */ '@Pages/PBUCampaign/saml'));
export const PBUCampaignHeader = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pbulandingpage" */ '@Pages/PBUCampaign/components/header'));
export const PBUCampaignFooter = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pbulandingpage" */ '@Pages/PBUCampaign/components/footer'));
export const PBUJulekampagne = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pbulandingpage" */ '@Pages/Julekampagne'));

export const BoligUnivers = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "bolig" */ '@Components/boligUnivers'));
export const BoligItem = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "bolig" */ '@Components/boligUnivers/components/boligItem'));

// Dictionary of available component types to be able to get a class from its literal name
const ComponentTypes: Record<string, any> = {
	'ChangeReport': ChangeReport,
	'ComparisonTable': ComparisonTable,
	'TilpasForsikringerFormular': TilpasForsikringerFormular,
	'KontaktOplysningerComponent': KontaktOplysningerComponent,
	'KontaktOs': KontaktOs,
	'KontaktOsApp': KontaktOsApp,
	'ListeAfSpoergsmaal': ListeAfSpoergsmaal,
	'MoedeBookingOversigt': MoedeBookingOversigt,
	'BookWebraadgivning': BookWebraadgivning,
	'BookWebraadgivningMedPensionsinfo': BookWebraadgivningMedPensionsinfo,
	'MoedeBookingEnkeltBegivenhed': MoedeBookingEnkeltBegivenhed,
	'SelvbetjeningBaseComponent': SelvbetjeningBaseComponent,
	'SelvbetjeningGageComponent': SelvbetjeningGageComponent,
	'SelvbetjeningGodkendComponent': SelvbetjeningGodkendComponent,
	'SelvbetjeningKvitteringComponent': SelvbetjeningKvitteringComponent,
	'SelvbetjeningStepsComponent': SelvbetjeningStepsComponent,
	'SelvbetjeningStepComponent': SelvbetjeningStepComponent,
	'SelvbetjeningTilpasForsikringComponent': SelvbetjeningTilpasForsikringComponent,
	'SelvbetjeningKontoAendringComponent': SelvbetjeningKontoAendringComponent,
	'SelvbetjeningSkattekortComponent': SelvbetjeningSkattekortComponent,
	'TekstOgMedieSlider': TekstOgMedieSlider,
	'TekstOgMedieSliderForside': TekstOgMedieSliderForside,
	'UdbetalingsForloeb': UdbetalingsForloeb,
	'HentPensionsinfoData': HentPensionsinfoData,
	'Wizard': Samlebank,
	'HelbredHovedSide': HelbredHovedSide,
	'HelbredUnderSide': HelbredUnderSide,
	'HelbredKontaktInfo': HelbredKontaktInfo,
	'HelbredTaleBobbel': HelbredTaleBobbel,
	'Slider': Slider,
	'ToKolonnerForskelligTekst': ToKolonnerForskelligTekst,
	'ToKolonnerMedBilleder': ToKolonnerMedBilleder,
	'FarvetLinkOgIkonBokse': FarvetLinkOgIkonBokse,
	'AabneSiderForside': AabneSiderForside,
	'ServicetjekResultat': ServicetjekResultat,
	'ServicetjekNy': ServicetjekNy,
	'MitidLoginComponent': MitidLoginComponent,
	'MitidSigningComponent': MitidSigningComponent,
	'MitidSigningOffentligComponent': MitidSigningOffentligComponent,
	'SsoPensionsinfo': SsoPensionsinfo,
	'WebRaadgivning': WebRaadgivning,
	'VideoQuoteBlockComponent': VideoQuoteBlockComponent,
	'VideoSkyfish': VideoSkyfish,
	'Accordion': Accordion,
	'AccordionContainer': AccordionContainer,
	'AccordionTabList': AccordionTabList,
	'SectionHeading': SectionHeading,
	'ImageDeck': ImageDeck,
	ServicetjekNavigation,
	'ServicetjekOverblik': LinkListe,
	ServicetjekTilmelding,
	MaalrettetMeddelelseListe,
	ServicetjekBilledeOgTekstListe,
	ServicetjekLivshistorie,
	'ServicetjekSamtykke': ServicetjekSamtykke,
	'ServicetjekAarligt20': ServicetjekAarligt20,
	'ServicetjekLoebende': ServicetjekLoebende,
	'RecommendationList': RecommendationList,
	'Hotspots': Hotspots,
	'OptionButtons': OptionButtons,
	'Tiles': Tiles,
	'RelatedContentSlider': RelatedContentSlider,
	'TextDeck': TextDeck,
	'TextBlock': TextBlock,
	'Image': Image,
	'IddFlow': IddFlow,
	'DitPKAHero': DitPKAHero,
	'DitPKASharing': DitPKASharing,
	'DitPKAFilter': DitPKAFilter,
	'RelatedArticles': RelatedArticles,
	'DitPkaListe': DitPKAListe,
	'QuoteBlockWithAuthor': QuoteBlock,
	'EmnePageHero': EmnePageHero,
	'CampainPageSectionHeading': CampainPageSectionHeading,
	'FrontPageHero': FrontPageHero,
	'EmployeeInfoBlock': EmployeeInfoBlock,
	ServicetjekMaalrettetMeddelelse,
	ServicetjekHero,
	ServicetjekLivshaendelse,
	ServicetjekOnboarding,
	'Karrusel': Karrusel,
	'FooterContact': FooterContact,
	'PensionsTjekFlow': PensionsTjekFlow,
	'Faktaboks': Faktaboks,
	'CprLogin': CprLogin,
	'MedlemsProfil': MedlemsProfil,
	'TilmeldNyhedsbrevMedlemskommunikation': TilmeldNyhedsbrevMedlemskommunikation,
	'PBUEkstra': PBUEkstra,
	'PBUSaml': PBUSaml,
	'PBUCampaignFooter': PBUCampaignFooter,
	'PBUCampaignHeader': PBUCampaignHeader,
	'PBUJulekampagne': PBUJulekampagne,
	'BoligUnivers': BoligItem,
	'BoligUniversContainer': BoligUnivers,
	'Survey': Survey
};


export const RenderComponent = (props: any) => {
	const { Component } = props;

	return (
		<Suspense fallback={ <LoadingSpinner isLoading={ true } loadingText="Vent venligst" /> }>
			{ Component }
		</Suspense>
	);
};

document.addEventListener('DOMContentLoaded', function () {

	// this clears the sessionstorage if the user has logged out
	if (getQueryParam('logout') === 'true') {
		sessionStorage.clear();
	}

	/** Mounts a React component based on container element attributes */
	function initialize(componentContainer:any) {
		const componentName = componentContainer.dataset.reactComponent;

		if (!ComponentTypes[componentName]) {
			console.warn('Unknown React component: ' + componentName);
			return;
		}

		// Pass initial props from attribute on component container element
		const props = JSON.parse(componentContainer.dataset.props || '{}');

		const Component = React.createElement(
			ComponentTypes[componentName], props
		);

		// Render the component by resolving its class based on name
		ReactDOM.render(<RenderComponent Component={ Component } />, componentContainer);
	}

	let reactComponents = document.querySelectorAll('[data-react-component]') || [];

	// If the user is using IE 11 or lower we need to convert the nodeList to a regular array
	reactComponents = Array.prototype.slice.call(reactComponents);

	// Mount React components
	reactComponents.forEach(initialize);
});

declare global {
    interface Window {
		App: any,
		CookieConsent: any,
		CookieInformation: any,
		videojs: any,
		ReactNativeWebView: any
	}
}